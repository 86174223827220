<template>
    <div class="subscribe-list-main">
        <!-- <van-tabs v-model="activeIndex" class="mytab" @change="tabChange" :title-style="tabStyle" color="#298AFB" >
            <van-tab title="预约中" name="wait">

            </van-tab>
            <van-tab title="已住院" name="zhuyuan">
                
            </van-tab>
        </van-tabs> -->
        <div class="subscrube-list">
            <!--  -->
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-if="tableData.length != 0">
                    
                    <template >
                        <van-swipe-cell v-for="(item,index) in tableData" :key="index">
                            <div    class="subscribeItem">
                                <div class="user-info" @click="goDetail(item)">
                                    <p class="info-details">
                                        <span class="name">{{item.patientName}}</span>
                                        <span class="sex" :class="item.gender == 'male'?'man':'woman'"></span>
                                        <span class="tag" :class="'hospotalStatue'+item.status">{{statusObj[item.status]}}</span>
                                    </p>
                                    <p class="order-infoitem">
                                        <img src="@/assets/img/time-icon.png" class="lable" alt="">
                                        <span class="txt">{{$t("subscribe.submitTime")}}：{{getLocalTime(item.zhuyuanCdt)}}</span>
                                    </p>
                                </div>
                                <div class="cancel" v-if="item.status ==  141001" @click="cancle(item)">{{$t("subscribe.cancelHandle")}}</div>
                            </div>
                        </van-swipe-cell>
                    </template>
            </van-pull-refresh>
            <div class="data-null" v-if="tableData.length == 0">
                <img src="@/assets/img/subscrubenull.png" >
                <p class="txt">{{$t("subscribe.noAdmission")}}</p>
                <div class="add-btn" @click="goSubscribe">{{$t("subscribe.handleDealWith")}}</div>
            </div>
        </div>
        <div class="addSubscribe" v-if="tableData.length != 0" @click="goSubscribe">
            <van-icon name="plus" />
        </div>
    </div>
</template>

<script>
import { patientQuery } from "@/api/patient";
import { Toast } from 'vant';
import { getLocalTime } from "@/utils/date";
import { admissionQuery, admissionCancel } from "@/api/index"
export default {
    data(){
        return{
            tableData:[],
            getLocalTime,
            loading: false,
            finished: false,
            refreshing: false,
            activeIndex:"zhuyuan",
            pageNum:1,
            tabStyle:{
                fontSize:"0.64rem"
            },
            statusObj:{
                "141001":this.$t("subscribe.statusTxt1"),
                "141002":this.$t("subscribe.statusTxt2"),
                "141003":this.$t("subscribe.statusTxt3"),
                "141004":this.$t("subscribe.statusTxt4")
            }
        }
    },
    created () {
        this.getSubscribeData();
        this.getPatientData();
        document.title = this.$t("subscribe.adminssiontitle")
    },
    methods:{
        goSubscribe(){
            if(this.$store.getters.patientData.length == 0){
                this.$store.commit("user/SET_SENDPATIENT",true);
                return;
            }
            this.$router.push({path:"/admission/add"})
        },
        cancle(item){
            const toast = this.$Toast.loading({
                message: this.$t("message.loadingTxt"),
                forbidClick: true,
            });
            console.log("342");
            admissionCancel({
                zhuyuanId:item.zhuyuanId
            }).then(res=>{
                toast.clear()
                if(res.data.errorCode == 0){
                    this.$toast(this.$t("comonTxt.operationsuccess"))
                    this.getSubscribeData()
                }else{
                    this.$toast(this.$t("comonTxt.operationfail"))
                }
            })
        },
        // 获取就诊人数据
		getPatientData(){
			
			patientQuery({}).then(res=>{
				if(res.data.errorCode == 0){
					if(res.data.data.length !== 0){                        
                        let result = res.data.data.find(item => item.id == this.$route.query.patientId);
                        if(result){
                            this.fromData.patientId = result.id;
                            this.fromData.patientName = result.name;
                        }
						this.$store.commit('user/SET_PATIENTDATA',res.data.data);
					}                    
				}else{
					this.$Toast(this.$t("patient.patientfail"))
				}
			})
		},
        tabChange(){
            this.finished = false;
            this.pageNum = 1;
            this.refreshing = false;
            // this.getSubscribeData();
            // this.onLoad();
        },
        async getSubscribeData(){
            // 
            const toast = Toast.loading({
                message: this.$t("message.loadingTxt"),
                forbidClick: true,
            });
            let datadd = {
                status:this.activeIndex
            }
            let result = await admissionQuery(datadd);
            toast.clear();
            if(this.refreshing){
                this.refreshing = false;
            }
            if(result.data.errorCode == 0){
                // this.$toast.success("预约成功");
                // this.$router.go(-1);
                this.tableData = result.data.data;
            }else{
                if(this.pageNum == 1){
                    this.tableData = [];
                }
            }
        },
        onRefresh(){
            this.finished = false;
            this.pageNum = 1;
            this.loading = true;
            this.getSubscribeData();
        },
        goDetail(item){
            
            this.$router.push({
                path:"/admission/details",
                query:{
                    zhuyuanId:item.zhuyuanId
                }
            })
        },

    }
}
</script>

<style lang="less" scoped>
.subscribe-list-main{
    display: flex;
    flex-direction: column;
    background-color: #F6F7FB;
    height: 100%;
    .addSubscribe{
        position: fixed;
        width: 1.92rem;
        height: 1.92rem;
        border-radius: 50%;
        background: #298AFB;
        right: 0.6rem;
        bottom: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .van-icon-plus{
            color: #fff;
            font-size: 0.8rem;
            font-weight: bold;
        }
    }
    .van-pull-refresh{
        min-height: 100vh;
    }
    /deep/ .mytab{
        flex: auto 0 0;
        height: 2rem;
        background-color: #fff;
        .van-tabs__wrap{
            min-height: 100%;
            .van-tab{
                font-size: 0.68rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                
                color: #9B9B9B;
                .van-tab__text {
                    height: 100%;
                    line-height: 2rem;
                }
            }
            .van-tab--active{
                color: #181818;
            }
            .van-tabs__line{
                width: 2rem;
            }
        }
    }
    .subscrube-list{
        flex: 1;
        overflow: auto;
            .van-cell{
                background: none;
            }
             /deep/ .van-list__finished-text{
                font-size: 0.6rem;
            }
            .subscribeItem{
                margin: 0.4rem 0.6rem 0 0.6rem;
                background-color: #fff;
                border-radius: 0.24rem;
                padding: 0.6rem;
                display: flex;
                align-items: center;
                &:last-child{
                    margin-bottom: 0.4rem;
                }
                .user-info{
                    flex: 1;
                    .info-details{
                        display: flex;
                        align-items: center;
                        margin-bottom: 0.4rem;
                        span{
                            margin-right: 0.3rem;
                            &:last-child{
                                font-size: 0.44rem;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #FFFFFF;
                                margin-left: 0.1rem;
                                padding: 0.06rem 0.34rem;
                                background-color: #FFC00F;
                                line-height: 0.6rem;
                                border-radius: 0.1 rem;
                            }
                        }
                        span.hospotalStatue141001{
                            background-color: #FFC00F;
                        }
                        span.hospotalStatue141002{
                            background-color: #49A7FF;
                        }
                        span.hospotalStatue141003{
                            background-color: #909399;
                        }
                        span.hospotalStatue141004{
                            background-color: #FF3535;
                        }
                        .sex{
                            width: 0.6rem;
                            height: 0.6rem;
                            background: url("~@/assets/img/man-icon.png") no-repeat;
                            background-size: 100% 100%;
                        }
                        .woman{
                            background: url("~@/assets/img/woman-icon.png") no-repeat;
                            background-size: 100% 100%;
                        }
                        .name{
                            font-size: 0.72rem;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #181818;
                            line-height: 1rem;
                        }
                    }
                    .order-infoitem{
                        margin-bottom: 0.3rem;
                        display: flex;
                        align-items: center;
                        &:last-child{
                            margin-bottom: 0;
                        }
                        img{
                            width: 0.4rem;
                            height: 0.4rem;
                            margin-right: 0.3rem;
                        }
                        .txt{
                            font-size: 0.52rem;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #696969;
                            line-height: 0.74rem;
                            
                        }
                    }
                }
                .cancel{
                    width: 3.1rem;
                    height: 1rem;
                    border-radius: 0.5rem;
                    line-height: 1rem;
                    text-align: center;
                    color: #49A7FF;
                    font-size: 0.52rem;
                    margin-left: 0.2rem;
                    border: 0.02rem solid #49A7FF;
                }
            }
        .data-null{
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            img{
                width: 6rem;
                height: 4rem;
            }
            .txt{
                font-size: 0.56rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9B9B9B;
                line-height: 0.8rem;
                margin: 0.6rem auto 0.8rem auto;
            }
            .add-btn{
                height: 1.8rem;
                width: 12.8rem;
                margin: 1rem auto;
                background: linear-gradient(180deg, #6EC9FF 0%, #0095FF 100%);
                border-radius: 0.9rem;
                color: #fff;
                font-size: 0.72rem;
                line-height: 1.8rem;
                text-align: center;
            }
        }
    }
}
</style>